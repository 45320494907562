import React, { useContext, useMemo } from 'react'
import { Menu } from './menu'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'

import { AuthContext } from 'Context'
import { injectIntl } from 'react-intl'

const MenuContainer = injectIntl(({ admin, location, onNav, intl }) => {
  const { user, organisation } = useContext(AuthContext)

  if (!onNav) {
    onNav = () => {
    }
  }

  const buildMenu = () => {
    if (admin) {
      return [
        {
          label: intl.formatMessage({ id: 'menu.start' }),
          url: '#/start',
          icon: 'pi pi-home',
          command: () => {
            onNav()
          }
        },
        {
          label: intl.formatMessage({ id: 'admin.edit_content' }),
          url: '#/admin/editStartPage',
          orgClassName: 'sub',
          icon: 'pi pi-pencil',
          command: () => {
            onNav()
          }
        },
        {
          label: intl.formatMessage({ id: 'faq.title' }),
          url: '#/admin/faq',
          icon: 'pi pi-question',
          command: () => {
            onNav()
          }
        },
        {
          label: intl.formatMessage({ id: 'orgmessages.title' }),
          url: '#/admin/messages',
          icon: 'pi pi-envelope',
          command: () => {
            onNav()
          }
        },
        {
          label: intl.formatMessage({ id: 'menu.revision' }),
          url: '#/admin/sdsRevisionSubscription/active',
          command: () => {
            onNav()
          },
          icon: 'pi pi-file'
        },
        {
          label: intl.formatMessage({ id: 'menu.products' }),
          url: '#/admin/products',
          icon: 'pi pi-list',
          command: () => {
            onNav()
          }
        },
        {
          label: intl.formatMessage({ id: 'chemicals.form.ingredients' }),
          url: '#/admin/ingredients',
          command: () => {
            onNav()
          },
          orgClassName: 'sub',
          icon: 'pi pi-th-large'
        },
        {
          label: intl.formatMessage({ id: 'common.filter' }),
          url: '#/admin/filter',
          command: () => {
            onNav()
          },
          orgClassName: 'sub',
          icon: 'pi pi-bars'
        },
        {
          label: intl.formatMessage({ id: 'menu.customers' }),
          url: '#/admin/organisations',
          icon: 'pi pi-user',
          command: () => {
            onNav()
          }
        },
        {
          label: intl.formatMessage({ id: 'admin.reports' }),
          url: '#/admin/reports',
          icon: 'pi pi-file',
          orgClassName: 'sub',
          command: () => {
            onNav()
          }
        },
        {
          label: intl.formatMessage({ id: 'admin.stray_users' }),
          url: '#/admin/strayUsers',
          icon: 'pi pi-users',
          orgClassName: 'sub',
          command: () => {
            onNav()
          }
        },
        {
          label: intl.formatMessage({ id: 'menu.log' }),
          url: '#/admin/log',
          icon: 'pi pi-desktop',
          command: () => {
            onNav()
          }
        }
      ]
    } else {
      let start = [
        {
          label: intl.formatMessage({ id: 'menu.start' }),
          url: '#/start',
          icon: 'pi pi-home',
          command: () => {
            onNav()
          }
        }
      ]
      let sdsItems = []

      if (organisation.has_sds && user.user.roleValues.sds !== 'not_authorized') {
        sdsItems = sdsItems.concat([
          {
            label: intl.formatMessage({ id: 'common.sds' }),
            url: '#/sds',
            command: () => {
              onNav()
            },
            icon: 'pi pi-list'
          }
        ])

        if (organisation.has_sds_revision && (user.isSuperAdmin || user.capabilities.includes('MANAGE_REVISIONS'))) {
          sdsItems = sdsItems.concat([
            {
              label: intl.formatMessage({ id: 'menu.revision' }),
              url: '#/sdsRevisionMaintenance',
              command: () => {
                onNav()
              },
              icon: 'pi pi-file'
            }
          ])
        }

        if (organisation.has_sds_revision_subscription && (user.isSuperAdmin || user.capabilities.includes('MANAGE_REVISIONS'))) {
          sdsItems = sdsItems.concat([
            {
              label: intl.formatMessage({ id: 'menu.revision' }),
              url: '#/sdsRevisionSubscription/active',
              command: () => {
                onNav()
              },
              icon: 'pi pi-file'
            }
          ])
        }

        if (organisation.weblink > 0 && (user.isSuperAdmin || user.capabilities.includes('MANAGE_SDS'))) {
          sdsItems = sdsItems.concat([
            {
              label: intl.formatMessage({ id: 'menu.weblinking' }),
              url: '#/sdsIframe',
              command: () => {
                onNav()
              },
              icon: 'pi pi-globe'
            }
          ])
        }
      }

      let chemItems = []

      if (organisation.has_chemical_list && user.user.roleValues.chemical_list !== 'not_authorized') {
        chemItems = chemItems.concat([
          {
            label: intl.formatMessage({ id: 'chemicals.chemical_list' }),
            url: '#/chemicalList',
            command: () => {
              onNav()
            },
            icon: 'pi pi-list'
          },
          {
            label: intl.formatMessage({ id: 'common.locations' }),
            url: '#/locations',
            command: () => {
              onNav()
            },
            icon: 'pi pi-sitemap'
          },
          {
            label: intl.formatMessage({ id: 'riskassessment.riskassessment' }),
            url: '#/chemicalRisk',
            command: () => {
              onNav()
            },
            icon: 'pi pi-eye'
          },
          {
            label: intl.formatMessage({ id: 'hygiene-thresholds.title' }),
            url: '#/hygieneThresholds',
            command: () => {
              onNav()
            },
            icon: 'pi pi-table'
          }
        ])

        if (organisation.has_inventorying && (user.isSuperAdmin || user.capabilities.includes('INVENTORYING_MANAGE'))) {
          chemItems.push({
            label: intl.formatMessage({ id: 'inventory.title' }),
            url: '#/inventory/active',
            command: () => {
              onNav()
            },
            icon: 'fas fa-cubes'
          })
        }

        if (organisation.qr_enabled) {
          chemItems.push({
            label: intl.formatMessage({ id: 'qr-codes.title-single' }),
            url: '#/qrCodes',
            command: () => {
              onNav()
            },
            icon: 'fas fa-qrcode'
          })
        }
      }

      const helpItems = [
        {
          label: intl.formatMessage({ id: 'faq.title' }),
          url: '#/faq',
          command: () => {
            onNav()
          },
          icon: 'pi pi-question-circle'
        }
      ]

      if (organisation.kind !== 'piece') {
        helpItems.push({
          label: intl.formatMessage({ id: 'codes.title' }),
          url: '#/codes',
          command: () => {
            onNav()
          },
          icon: 'pi pi-info'
        })
      }

      return start
        .concat(sdsItems.length > 0 ? [{ label: intl.formatMessage({ id: 'sds.module' }), items: sdsItems }] : [])
        .concat(chemItems.length > 0 ? [{
          label: intl.formatMessage({ id: 'chemicals.module' }),
          items: chemItems
        }] : [])
        .concat(helpItems.length > 0 ? [{ label: '', items: helpItems }] : [])
    }
  }

  const items = useMemo(buildMenu, [admin, organisation, intl])

  items.forEach((item) => {
    item.className = classNames(item.orgClassName, { active: item.url && item.url.substring(1) === location.pathname })
    if (item.items) {
      item.items.forEach((item) => {
        item.className = classNames(item.orgClassName, { active: item.url && item.url.substring(1) === location.pathname })
      })
    }
  })

  return <Menu model={items} className="sidemenu"/>
})

export const NavMenu = withRouter(MenuContainer)
