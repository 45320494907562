import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { MultiSelect } from '.'
import useCachedGetData from 'Hooks/useCachedGetData.js'
import _ from "lodash";

export const LanguageMultipleSdsDropdown = ({ value: propValue, ...props }) => {
  const intl = useIntl()
  const [isLoading, langList] = useCachedGetData('/rapi/sds/sdsFilteredLangList', [], [intl.locale])
  const [options, setOptions] = useState([])

  // Ensure value is always an array (prevents null issues)
  const [selectedLanguages, setSelectedLanguages] = useState(
    Array.isArray(propValue) ? propValue : []
  );

  const defaultLabel = props.defaultLabel
    ? props.defaultLabel
    : intl.formatMessage({ id: 'sds.select_language' });

  useEffect(() => {
    setOptions([{ label: defaultLabel, value: 0 }, ...langList])
  }, [langList, intl, defaultLabel]);

  /**
   * Customize the multiselect labels
   *
   * @param option
   * @returns {JSX.Element}
   */
  const tagTemplate = (option) => {
    let title = (typeof option === "string") ? option : defaultLabel;
    options.forEach((row) => {
      if(row.value === title) {
        title = row.label;
      }
    });
    return <>
      <span>{title}</span>
    </>
  }

  /**
   * Handles the default behavior for triggering multiple active language changes.
   * Note: The first option is automatically added for choosing all the languages
   *
   * @param e
   */
  const onLangChange = (e) => {
    let value;
    if(e.value.length > 0 && e.value[e.value.length-1] === 0) {
      value = [0];
    } else {
      value = e.value.filter((item) => item !== 0);
      value = Array.isArray(value) ? value : [];
      if(value.length >= (options.length-1)) {
        value = [0];
      }
    }
    setSelectedLanguages(value);
    if(typeof props?.onUpdate === "function") {
      props.onUpdate(e, value);
    }
  }

  return <MultiSelect
      style={{ maxWidth: '200px' }}
      options={options}
      value={selectedLanguages}
      onChange={onLangChange}
      dropdownStyle={true}
      selectedItemTemplate={tagTemplate}
      placeholder={defaultLabel}
      disabled={isLoading}
      {...props}
    />
};
