import React from 'react'
import styled from 'styled-components'
import { MultiSelect as MS } from 'primereact/multiselect'
import colors from '../colors.scss'
import { useIntl } from 'react-intl'

export const StyledMultiSelect = styled(MS)`
  &&& {
    background-color: ${(props) => (props.theme === 'dark' ? colors.darkPanel : colors.white)};
    border: 2px solid ${(props) => (props.theme === 'dark' ? colors.darkPanel : colors.white)};
    border-radius: 20px;
    padding: 0 30px 0 20px;
    width: 100%;
    max-width: 243px !important;

    &:hover:not(.p-disabled) {
      border-color: ${(props) => (props.dark ? colors.invert : colors.white)};
    }

    &.p-focus {
      box-shadow: none;
      border-color: ${colors.primary};
    }

    .p-multiselect-trigger {
      background-color: transparent;
      top: 2px;
      color: ${colors.midGrey};
    }

    /* Label */
    .p-multiselect-label {
      background-color: transparent;
      color: ${(props) => (props.theme === 'dark' ? colors.white : colors.invert)};
      margin: 0;
      padding-left: 0;
      padding-right: 0;
      text-overflow: ellipsis
    }

    .p-multiselect-header {
      display: ${(props) => (props.dropdownStyle ? 'none' : "block")};
    }


    .p-multiselect-label span + span::before {
      content: ", ";
    }

    /* Dropdown Items */
    .p-multiselect-panel {
      background-color: ${(props) => (props.theme === 'dark' ? colors.darkPanel : colors.white)};
      border-radius: 4px;
      border: none;
      overflow: hidden;


      .p-multiselect-items-wrapper {
        padding: ${(props) => (props.dropdownStyle ? 0 : "3px")} 0;
      }

      .p-checkbox {
        display: ${(props) => (props.dropdownStyle ? 'none' : "inline-block")};
      }

      .p-checkbox .p-checkbox-box .p-checkbox-icon {
        color: ${colors.primary};
      }

      .p-multiselect-items {
        .p-multiselect-item {
          background-color: ${(props) => (props.theme === 'dark' ? colors.darkBackground : colors.white)};
          color: ${(props) => (props.theme === 'dark' ? colors.white : colors.invert)};

          &:hover:not(.p-disabled) {
            background-color: ${colors.primaryFaded};
            color: ${colors.white};
          }

          &.p-highlight {
            background-color: ${colors.primary};
            color: ${colors.white};
          }
        }
      }
    }
  }
  .p-multiselect-label {
    background-color: ${(props) => (props.dark ? colors.invert : colors.white)};
    color: ${(props) => (props.dark ? colors.white : colors.defaultInteractText)};
    white-space: nowrap;
    margin: 0;
    padding: 9px 0 !important;
    min-height: 33px;
    max-width: 600px;
  }

  @media screen and (max-width: 640px) {
    max-width: 250px;
  }
`;

export const MultiSelect = (props) => {
  const intl = useIntl();
  return <StyledMultiSelect selectedItemsLabel={intl.formatMessage({ id: 'common.items_selected' })} {...props} />;
};
