import * as React from 'react'
import { useContext, useMemo, useState } from 'react'
import { addMonths, format, parse, startOfMonth } from 'date-fns'
import sv from 'date-fns/locale/sv';
import { Column } from 'primereact/column'
import { injectIntl } from 'react-intl'
import { AuthContext } from 'Context'
import { MonthRangePicker, SearchInput } from 'Components/input'
import { Badge, DataTable, ToolPanel } from 'Components/readonly'
import { FlexRowCenterResponsive, Spacer } from 'Components/layout'
import RevisionDetail from './revisionDetail'
import { preserveNewlinesColumnBody } from 'Utils/newLines'
import { globalSearch } from 'Utils/search'

const capitalizeFirst = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const RevisionTable = injectIntl(({
                                    children,
                                    view,
                                    isLoading,
                                    sds,
                                    readSds,
                                    updateSds,
                                    deleteSds,
                                    intl
                                  }: { children?: React.Node }) => {
  // const { dateLocaleDateFns } = useContext(AuthContext)
  const [globalFilter, setGlobalFilter] = useState('')
  const minFromDate = startOfMonth(addMonths(new Date(), 3))
  const [fromDate, setFromDate] = useState(minFromDate)
  const [toDate, setToDate] = useState(addMonths(new Date(), 9))
  const [showDetailId, setShowDetailId] = useState(null)
  const [first, setFirst] = useState(0)

  const statusBody = (row, column) => {
    if (row.revisionAction) {
      if (row.revisionAction.action === 'disable') return <Badge fill faded
                                                                 label={intl.formatMessage({ id: 'sds.cancel-revision' })}/>
      if (row.revisionAction.action === 'revision')
        return <Badge fill accent label={intl.formatMessage({ id: 'sds.revised-with-marks' })}/>
    }
    return <Badge fill label={intl.formatMessage({ id: 'sds.revise' })}/>
  }

  const monthBody = (row, column) => {
    const formatedDate = capitalizeFirst(format(parse(row[column.field]), 'MMMM YYYY', { locale: sv }));
    return <span>{formatedDate}</span>
  }


  const showDetailSds = readSds(showDetailId)

  const filteredSds = useMemo(() => {
    let filteredList = globalSearch([...sds], globalFilter, ['productName', 'countryName', 'langName'])
    // If no date filter set return list without filtering
    if ((!fromDate && !toDate) || view !== 'UPCOMING') {
      return filteredList
    }
    const fromMonth = fromDate ? format(fromDate, 'YYYY-MM') : null
    const toMonth = toDate ? format(toDate, 'YYYY-MM') : null
    return filteredList.filter((sds) => {
      const sdsMonth = format(parse(sds.expires), 'YYYY-MM')
      if (fromMonth && sdsMonth.localeCompare(fromMonth) < 0) {
        return false
      }
      if (toMonth && sdsMonth.localeCompare(toMonth) > 0) {
        return false
      }
      return true
    })
  }, [sds, fromDate, toDate, view, globalFilter])

  const onFromDateChange = (value) => {
    if (value) {
      setFromDate(value)
    }
  }

  const onToDateChange = (value) => {
    if (value) {
      setToDate(value)
    }
  }

  return (
    <>
      <ToolPanel>
        <SearchInput
          value={globalFilter}
          onChange={(event) => {
            setGlobalFilter(event.target.value)
            setFirst(0)
          }}
        />
        <Spacer/>
        {view === 'UPCOMING' && (
          <FlexRowCenterResponsive>
            <MonthRangePicker
              toDate={toDate}
              fromDate={fromDate}
              minFromDate={minFromDate}
              onFromDateChange={onFromDateChange}
              onToDateChange={onToDateChange}
            />
          </FlexRowCenterResponsive>
        )}
      </ToolPanel>
      <DataTable
        className={'flextable' + (view !== 'UPCOMING' ? ' disabled-rows' : ' clickable-rows')}
        value={filteredSds}
        paginator={true}
        rows={10}
        first={first}
        onPage={(e) => setFirst(e.first)}
        loading={isLoading}
        onRowClick={(event) => {
          if (view === 'UPCOMING') {
            setShowDetailId(event.data.id)
          }
        }}
        responsive={true}>
        <Column
          field="productName"
          header={intl.formatMessage({ id: 'chemicals.product' })}
          body={preserveNewlinesColumnBody}
          sortable={true}
        />
        <Column field="id" header={intl.formatMessage({ id: 'sds.id' })} sortable={true} style={{ width: '110px' }}/>
        <Column
          field="expires"
          header={intl.formatMessage({ id: 'sds.revision-month' })}
          sortable={true}
          body={monthBody}
          style={{ width: '1%' }}
        />
        <Column
          field="revisionAction.action"
          header={intl.formatMessage({ id: 'common.status' })}
          sortable={true}
          body={statusBody}
          style={{ width: '1%' }}
        />
        <Column
          field="countryName"
          header={intl.formatMessage({ id: 'common.country' })}
          sortable={true}
          style={{ width: '1%', whiteSpace: 'nowrap' }}
        />
        <Column
          field="langName"
          header={intl.formatMessage({ id: 'common.language' })}
          sortable={true}
          style={{ width: '1%', whiteSpace: 'nowrap' }}
        />
      </DataTable>
      <RevisionDetail
        sds={showDetailSds}
        reviseBy={showDetailSds && showDetailSds.expires}
        kind="internal"
        isOpen={showDetailSds != null}
        close={() => {
          setShowDetailId(null)
        }}
        updateSds={updateSds}
        deleteSds={deleteSds}
      />
    </>
  )
})

export default RevisionTable
