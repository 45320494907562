import React, {useEffect} from "react";
import { ProductPage } from './productPage';
import styled from 'styled-components'

const ModalWrapper = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: #41404A;
  position: absolute;
  z-index: 100;
  top: -110px;
  padding-bottom: 100%;
  left: 0;
`;

const ProductModalWrapper = ({ productId, onClose }) => {

  /**
   * Will close product modal wrapper and updates the URL without triggering a
   * re-render or state update
   */
  const closeModal = () => {
    if (onClose) {
      onClose();
      window.history.pushState({}, '', `/#/chemicalList`);
    }
  };

  /**
   * This effect is used to updates the URL without triggering a re-render or state update
   */
  useEffect(() => {
    if (productId) {
      const contentContainerElem = document.getElementById('content-container');
      window.history.pushState({}, '', `/#/product/${productId}`);
      if (contentContainerElem) {
        document.getElementById('content-container').scrollTop = 0;
      }
    }
  }, [productId])

  return (
    <ModalWrapper className={onClose ? 'modal-overlay' : ''} onClick={onClose}>
      <div className={onClose ? 'modal-content' : ''} onClick={(e) => e.stopPropagation()}>
        <ProductPage productId={productId} closeModal={closeModal}/>
      </div>
    </ModalWrapper>
  );
};

export default ProductModalWrapper;
