import React, { useContext, useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

import colors from 'Components/colors.scss'
import AppHeader from './appHeader'
import AppRouter from './appRouter'
import { NavMenu } from 'Components/readonly'
import { AuthContext } from 'Context'
import { ContentContainer, LayoutContainer, MainContainer } from 'Components/layout'
import { Banner } from "../Components/readonly/banner";
import { FormattedMessage, useIntl } from 'react-intl'

const MenuContainer = styled.div`
  background-color: ${colors.menuBackgroudColor};
  overflow-y: auto;
`

const AppLayout = ({ location }) => {
  const containerRef = useRef()
  const { user } = useContext(AuthContext)
  const intl = useIntl()
  const bannerNotice = intl.formatMessage({ id: 'common.banner_notice', defaultMessage: '' })

  // Make sure that pages starts at top when navigating from a page that has scrolled
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0
    }
  }, [location.pathname])

  return (
    <LayoutContainer>
      <MainContainer>
        {(user.actingAsSuperAdmin || location.pathname !== '/start') && (
          <MenuContainer className="lg-show">
            <NavMenu admin={user.actingAsSuperAdmin}/>
          </MenuContainer>
        )}
        <ContentContainer id="content-container" ref={containerRef}>
          {bannerNotice && bannerNotice !== 'common.banner_notice' && (
            <Banner>
              <FormattedMessage id="common.banner_notice" defaultMessage=""/>
            </Banner>
          )}
          <AppRouter admin={user.actingAsSuperAdmin}/>
        </ContentContainer>
      </MainContainer>
      <AppHeader admin={user.actingAsSuperAdmin}/>
    </LayoutContainer>
  )
}

export default withRouter(AppLayout)
