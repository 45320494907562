import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link, withRouter } from 'react-router-dom'
import useGetData from 'Hooks/useGetData'
import useModalController from 'Hooks/useModalController'

import { BackLinkButton, Button, Divider, Modal, RichTextDisplay, TabView, ToolPanel } from 'Components/readonly'
import { TabPanel } from 'primereact/tabview'
import ArchiveProductDialog from './dialogs/archiveProductDialog'
import DownloadProductSDBDialog from './dialogs/downloadProductSDBDialog'
import UploadProductSDBDialog from './dialogs/uploadProductSDBDialog'
import RiskAssessmentSidebar from '../chemicalRisk/components/riskAssessmentSidebar'
import { EmptyRiskAssessment } from '../chemicalRisk/components/riskAssessmentForm'
import SafetySheetLink from '../chemicalRisk/components/SafetySheetLink'
import LocationPanel from './panels/locationPanel'

import { FormattedMessage, useIntl } from 'react-intl'
import {
  FlexColumn,
  FlexRow,
  FlexRowCenter,
  LineHeading,
  LongerSection,
  PageConstraint,
  PageHeader,
  Section,
  Spacer
} from 'Components/layout'
import { ProgressSpinner } from 'primereact/progressspinner'
import RiskAssessmentPreview from '../chemicalRisk/components/riskAssessmentPreview'
import ModalContentConfirm from 'Components/combined/modalContentComponents/modalContentConfirm'
import axios from 'axios'
import _ from 'lodash'
import { AuthContext, GrowlContext } from 'Context'
import ClassificationPanel from './panels/classificationPanel'
import FirstAidPanel from './panels/firstAidPanel'
import FireFightingPanel from './panels/fireFightingPanel'
import WasteTransportPanel from './panels/wasteTransportPanel'
import ReleaseExposurePanel from './panels/ReleaseExposurePanel'
import { MultiSelect } from 'Components/input'
import { HasCapability, useCapability } from 'Components/capabilities'
import { InventoryLockMessage } from 'Pages/inventory/components/inventoryLockMessage'
import colors from 'Components/colors.scss'

const Supplier = styled.h3`
  padding: 0;
`

const ProductHeader = styled(FlexRow)`
  flex-wrap: wrap;

  ${FlexColumn} {
    min-width: 300px;
  }

  > div ${Button} {
    margin-top: 10px;
    margin-bottom: 0 !important;
  }
`

const MultiSelectIcon = styled.div`
  display: inline-block;
  padding-right: 10px;
`

const ProductWrapper = styled.div`
  h1 {
    padding: 0;
    margin: 0;
  }

  > h3 {
    padding: 0;
    margin: 0 0 15px 0;
  }

  /* Make TabView height match tallest TabPanel, this avoids scrolling when switching tabs with different content heights. TabView must have renderActiveOnly={false} */

  ${TabView} {
    .p-tabview-panels {
      position: relative;

      .p-tabview-panel {
        &.p-hidden {
          display: block;
          visibility: hidden;
          position: absolute;
          top: 0px;
        }
      }
    }
  }
`

const NotTrustworthy = styled.div`
  margin: 20px 0 0 0;
  border: 2px solid ${colors.red};
  border-radius: 10px;
  padding: 0 20px 10px 10px;

  i {
    font-size: 30px;
    padding: 0 10px 0 0;
  }
`

const NoRiskAssessments = styled.div`
  display: flex;
  margin-bottom: 10px;

  ${SafetySheetLink} {
    flex: 1 1 auto;
  }
`

const EditArticleNumberAndSynonym = styled.div`
  margin-top: 20px;

  i {
    position: relative;
    top: 2px;
    margin-right: 10px;
  }
`

export const ProductPage = withRouter(({ history, match, productId, closeModal }) => {
  const intl = useIntl()
  const isModal = (productId ?? false);
  const getProductID = (match?.params?.id ?? productId);
  const { organisation } = React.useContext(AuthContext)
  const { displayError, displaySuccess } = React.useContext(GrowlContext)
  const [isDeleteDlgOpen, setDeleteProductDlgOpen] = useState(false)
  const [downloadModalController] = useModalController({ title: intl.formatMessage({ id: 'sds.download_sdb' }) })
  const [uploadModalController] = useModalController({ title: intl.formatMessage({ id: 'sds.upload_sdb' }) })
  const [archiveAssessmentModalController] = useModalController({
    title: intl.formatMessage({ id: 'chemicals.archive_assessment_title' })
  })
  const [selectedRiskAssessment, setSelectedRiskAssessment] = useState(null)
  const [isLoading, product, setProduct] = useGetData('/rapi/chemicals/' + getProductID, null, [organisation])
  const [isLoadingChemicalLabel, setIsLoadingChemicalLabel] = useState(false)

  // Tags
  const [isLoadingOrgTags, organisationTags] = useGetData('/rapi/tags', [], [organisation])
  const [selectedTags, setSelectedTags] = useState([])
  const [isSavingTags, setIsSavingTags] = useState(false)
  const hasChemicalUseTagsCapability = useCapability('CHEMICAL_USE_TAGS')

  useEffect(() => {
    if (product) {
      setSelectedTags(product.tags.map((tag) => +tag.value))
    }
  }, [product])

  const onChangeTags = (value) => {
    setSelectedTags(value)
    saveTags(value)
  }

  const saveTags = async (value) => {
    setIsSavingTags(true)
    try {
      await axios.put(`/rapi/chemicals/${product.id}/tags`, { tagIdList: value })
    } catch (error) {
      displayError(error)
      console.error(error)
    }
    setIsSavingTags(false)
  }

  const mapListToMultiSelect = (list) => {
    return list.map((entry) => {
      return { label: entry.name, value: entry.id }
    })
  }

  const backToChemList = () => {
    if(isModal) {
      closeModal();
    } else {
      history.push('/chemicalList')
    }
  }

  const handleArchiveClose = (success) => {
    if (success) {
      history.push('/chemicalList')
    }
    setDeleteProductDlgOpen(false)
  }

  const handleUpdateRiskAssessments = (updatedRiskAssessments) => {
    setProduct({ ...product, risk_assessments: updatedRiskAssessments })
  }

  const onArchiveAssessment = async (modalData) => {
    const result = await axios.post('/rapi/riskassessments/archive/' + modalData.assessment.id)
    handleUpdateRiskAssessments(result.data.riskAssessments)
    displaySuccess(result.data.message)
  }

  const tagTemplate = (option) => {
    if (option && organisationTags.length > 0) {
      if (selectedTags.indexOf(option) === selectedTags.length - 1) {
        return <>{organisationTags[_.findIndex(organisationTags, { id: option })].name}</>
      }
      return (
        <>
          {organisationTags[_.findIndex(organisationTags, { id: option })].name}
          {', '}
        </>
      )
    }
    return <> </>
  }

  const showOrganisationArticleNumber = organisation.enable_chemical_list_article_numbers && product && product.organisation_article_number
  const showOrganisationSynonym = organisation.enable_chemical_list_synonyms && product && product.organisation_synonym

  const downloadChemicalLabel = async (event) => {
    setIsLoadingChemicalLabel(true)
    try {
      window.open(`/rapi/chemicals/${product.id}/label`)
      event.preventDefault()
      event.stopPropagation()
    } catch (error) {
      displayError(error)
    }
    setIsLoadingChemicalLabel(false)
  }

  const ingredientNotTrustworthy =
    product && product.chemical_ingredients && product.chemical_ingredients.some((ci) => ci.ingredient_variant.classification_incorrect)

  return (
    <>
      <InventoryLockMessage />
      <PageConstraint>
        <ProductWrapper>
          {isLoading ? (
            <PageHeader>
              <p>
                <BackLinkButton
                  link
                  icon="pi pi-arrow-left"
                  label={intl.formatMessage({ id: 'chemicals.chemical_list' })}
                  onClick={backToChemList}
                />
              </p>
              <ProgressSpinner />
            </PageHeader>
          ) : (
            product && (
              <>
                <ArchiveProductDialog
                  isOpen={isDeleteDlgOpen}
                  productId={getProductID}
                  close={handleArchiveClose}
                  deleteProduct={null}
                />
                <Modal
                  modalController={archiveAssessmentModalController}
                  ContentComponent={ModalContentConfirm}
                  contentTextId="chemicals.archive_assessment_text"
                  confirmTextId="common.archive"
                  onConfirm={onArchiveAssessment}
                />
                <Modal modalController={downloadModalController} ContentComponent={DownloadProductSDBDialog} />
                <Modal modalController={uploadModalController} ContentComponent={UploadProductSDBDialog} />

                <RiskAssessmentSidebar
                  product={product}
                  selectedRiskAssessment={selectedRiskAssessment}
                  updateRiskAssessments={handleUpdateRiskAssessments}
                  isOpen={selectedRiskAssessment != null}
                  close={() => {
                    setSelectedRiskAssessment(null)
                  }}
                />
                <PageHeader>
                  <p>
                    <BackLinkButton
                      link
                      icon="pi pi-arrow-left"
                      label={intl.formatMessage({ id: 'chemicals.chemical_list' })}
                      onClick={backToChemList}
                    />
                  </p>
                  <ProductHeader>
                    <FlexColumn>
                      <Section>
                        <h1 id="name">{showOrganisationSynonym ? product.organisation_synonym : product.name}</h1>
                        <Supplier id="supplier">{product.supplier}</Supplier>
                        {showOrganisationSynonym && (
                          <>
                            <LineHeading>
                              <FormattedMessage id="chemicals.original_product_name" />
                            </LineHeading>
                            : {product.name}
                          </>
                        )}
                      </Section>
                      <div>
                        <LineHeading>
                          <FormattedMessage id="chemicals.form.article_number" />
                        </LineHeading>
                        : <span id="article_number">{product.article_number}</span>
                        <br />
                        {showOrganisationArticleNumber && (
                          <>
                            <LineHeading>
                              <FormattedMessage id="chemicals.organisation_article_number" />
                            </LineHeading>
                            : <span id="article_number">{product.organisation_article_number}</span>
                            <br />
                          </>
                        )}
                        <LineHeading>
                          <FormattedMessage id="chemicals.form.identified_uses" />
                        </LineHeading>
                        : <RichTextDisplay id="identified_uses" value={product.identified_uses} />
                      </div>
                    </FlexColumn>
                    <div>
                      <HasCapability capability="CHEMICAL_REMOVE">
                        <Button
                          invert
                          label={intl.formatMessage({ id: 'chemicals.delete-product-from-chemical-list' })}
                          icon="pi pi-trash"
                          onClick={() => setDeleteProductDlgOpen(true)}
                        />
                      </HasCapability>
                    </div>
                  </ProductHeader>
                  <HasCapability capability="CHEMICAL_MANAGE_TAGS">
                    {(organisation.enable_chemical_list_article_numbers || organisation.enable_chemical_list_synonyms) && (
                      <EditArticleNumberAndSynonym>
                        <Link
                          to={{
                            pathname: '/settings/manage_article_numbers_and_synonyms',
                            state: { productName: product.name }
                          }}>
                          <i className="pi pi-cog" />
                          <FormattedMessage id="chemicals.edit_article_number_and_synonym_link" />
                        </Link>
                      </EditArticleNumberAndSynonym>
                    )}
                  </HasCapability>
                  <Divider />
                  <ToolPanel>
                    <FlexRowCenter>
                      <MultiSelectIcon>
                        <i className="pi pi-tags"></i>
                      </MultiSelectIcon>
                      <MultiSelect
                        dark
                        value={selectedTags}
                        options={mapListToMultiSelect(organisationTags)}
                        onChange={(e) => {
                          onChangeTags(e.value)
                        }}
                        filter={true}
                        disabled={isLoadingOrgTags || !hasChemicalUseTagsCapability}
                        selectedItemTemplate={tagTemplate}
                      />
                      {(isLoadingOrgTags || isSavingTags) && <i className="pi pi-spinner pi-spin" />}
                    </FlexRowCenter>
                    <Spacer />
                    {product.status === 'ACTIVE' && (
                      <HasCapability capability="CHEMICAL_UPLOAD_DOCUMENT">
                        <Button
                          invert
                          label={intl.formatMessage({ id: 'sds.upload_sdb' })}
                          icon="pi pi-upload"
                          onClick={() => uploadModalController.open({ data: { productId: getProductID } })}
                        />
                      </HasCapability>
                    )}
                    <HasCapability capability="CHEMICAL_PRINT">
                      <Button
                        label={intl.formatMessage({ id: 'sds.download_sdb' })}
                        icon="pi pi-download"
                        onClick={() => downloadModalController.open({ data: { productId: getProductID } })}
                      />
                    </HasCapability>
                    {product.status !== 'PRELIMINARY' && (
                      <HasCapability capability="CHEMICAL_PRINT">
                        <Button
                          label={intl.formatMessage({ id: 'chemicals.chemical_label.title' })}
                          icon="pi pi-download"
                          onClick={(event) => downloadChemicalLabel(event)}
                          isLoading={isLoadingChemicalLabel}
                        />
                      </HasCapability>
                    )}
                  </ToolPanel>
                  <h2>
                    <FormattedMessage id="chemicals.riskassessments" />
                  </h2>
                  {(!product.risk_assessments || product.risk_assessments.length === 0) && product.status !== 'PRELIMINARY' && (
                    <NoRiskAssessments>
                      <FormattedMessage id="chemicals.no_riskassessments" />
                      <SafetySheetLink product={product} />
                    </NoRiskAssessments>
                  )}
                  {product.risk_assessments.map((riskAss, index) => (
                    <RiskAssessmentPreview
                      key={index}
                      product={product}
                      riskAssessment={riskAss}
                      horizontal={true}
                      archiveRiskAssessment={(assessment) => archiveAssessmentModalController.open({ data: { assessment } })}
                      setSelectedRiskAssessment={setSelectedRiskAssessment}
                    />
                  ))}
                  <LongerSection>
                    <ToolPanel>
                      <HasCapability capability="CHEMICAL_MANAGE_RISK_ASSESSMENTS">
                        <Button
                          style={{ margin: '0 10px 0 0' }}
                          primary
                          label={intl.formatMessage({ id: 'chemicals.new_risk_assessment' })}
                          icon="pi pi-plus"
                          onClick={() => setSelectedRiskAssessment(EmptyRiskAssessment)}
                        />
                      </HasCapability>
                      <Button
                        className="link-button"
                        link
                        icon="pi pi-folder"
                        onClick={() =>
                          history.push({
                            pathname: '/chemicalRisk',
                            state: { productName: product.name }
                          })
                        }
                        label={intl.formatMessage({ id: 'riskassessment.archived-riskassessments' })}
                      />
                    </ToolPanel>
                  </LongerSection>
                </PageHeader>
                <TabView renderActiveOnly={false}>
                  <TabPanel header={intl.formatMessage({ id: 'chemicals.form.classification_marking_short' })}>
                    <ClassificationPanel product={product} />
                  </TabPanel>
                  <TabPanel header={intl.formatMessage({ id: 'chemicals.form.first_aid' })}>
                    <FirstAidPanel product={product} />
                  </TabPanel>
                  <TabPanel header={intl.formatMessage({ id: 'chemicals.form.fire_protection' })}>
                    <FireFightingPanel product={product} />
                  </TabPanel>
                  <TabPanel header={intl.formatMessage({ id: 'chemicals.form.release_exposure' })}>
                    <ReleaseExposurePanel product={product} />
                  </TabPanel>
                  <TabPanel header={intl.formatMessage({ id: 'chemicals.form.waste_transport' })}>
                    <WasteTransportPanel product={product} />
                  </TabPanel>
                  <TabPanel header={intl.formatMessage({ id: 'common.locations' })}>
                    <LocationPanel product={product} setProduct={setProduct} />
                  </TabPanel>
                </TabView>
              </>
            )
          )}
        </ProductWrapper>
      </PageConstraint>
    </>
  )
})
