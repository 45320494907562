import React, { useContext, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { Column } from 'primereact/column'

import {AuthContext, GrowlContext} from 'Context'
import useCrudData from 'Hooks/useCrudData'
import { getLocationDisplayName } from 'Utils/location'

import { BadgeList, Button, ToolPanel } from 'Components/readonly'
import {FilterTableContainer, FlexRow, TableContainer, ThinHeaderDataTable} from 'Components/layout'
import ChemicalFilter, { filterColors } from 'Components/combined/chemicalFilter'
import { SidebarWrapperMobile, SidebarWrapperPage } from 'Components/combined/sidebarWrapper'
import ProductModalWrapper from './ProductModalWrapper';
import { TableMarkings } from 'Components/readonly/markings'
import ArchiveProductDialog from './dialogs/archiveProductDialog'
import _ from 'lodash'
import axios from "axios";

export const ChemicalList = ({ inputFilter, filter, history, archive, passProductCount, defaultSortOrder, first, setFirst, page, setPage }) => {
  const intl = useIntl()
  const [selectProductPage, setSelectProductPage] = useState(false);
  const [deleteProductId, setDeleteProductId] = useState(null)
  const { user, organisation } = useContext(AuthContext)
  const [rows, setRows] = useState(localStorage.hasOwnProperty('numChemicalRows') ? parseInt(localStorage.getItem('numChemicalRows')) : 10)
  const [sort, setSort] = useState({ field: defaultSortOrder, order: -1 })
  const { displayError, displaySuccess } = React.useContext(GrowlContext)
  const [seed, setSeed] = useState(1);

  useEffect(() => {
    localStorage.setItem("numChemicalRows", rows);
  }, [rows]);

  const getUrlParams = (page, rows, inputFilter) => {
    let filters = {
      candidate: filter.candidate,
    }
    let possibleFilters = [
      'he',
      'pd',
      'prio',
      'listingsList',
      'tagList',
      'remarkList',
      'location',
    ];

    _.each(possibleFilters, (filterProperty) => {
      if (filter[filterProperty] !== undefined && filter[filterProperty].length > 0) {
        filters[filterProperty] = _.map(filter[filterProperty], 'value');
      }
    })

    if (filter.locations.selectedKeys !== undefined && filter.locations.selectedKeys.length > 0) {
      filters.locations = filter.locations.selectedKeys;
    }

    let urlParams = {
      page: page,
      filters: filters,
      numItems: rows,
      order_by: sort.field,
      order_direction: sort.order,
    };

    if (inputFilter) {
      urlParams.search = inputFilter
    }

    if (filters.length > 0) {
      urlParams.filters = filters;
    }

    urlParams.filters = JSON.stringify(urlParams.filters);

    return new URLSearchParams(urlParams).toString();
  }

  // eslint-disable-next-line
  const [isLoading, chemicalList, createProduct, readProduct, updateProduct, deleteProduct] = useCrudData(
    '/rapi/chemicals/organisation' +
    (archive ? '/archived' : '') +
    ('?' + getUrlParams(page, rows, inputFilter)),
    [],
    checks(),
    (data) => {
      passProductCount(data.total ? data.total : 0);
      data.data = data.data.map((chemical) => {
        chemical.locationDisplayname = chemical.locations.map((location) => getLocationDisplayName(location.location)).join('; ')
        return chemical
      })
      return data;
    },
    inputFilter?.length == 1 || inputFilter?.length == 2 // Add debounce functionality to avoid dissappearing content connected to MySQL 3 character search limit
  )

  function checks() {
    return [organisation, page, inputFilter]
  }

  const showProduct = (row) => {
    if (!archive) {
      // Opens ProductPage in a modal to retain filter options but keeps full-page
      // access through state change
      // history.push('/product/' + row.id)
      setSelectProductPage(row.id);
    }
  }

  const statusBody = (row, column) => {
    return intl.formatMessage({ id: 'chemicals.status.' + row[column.field] })
  }

  const columns = [
    <Column key="article_number" field="article_number" header={intl.formatMessage({ id: 'chemicals.article_id' })}
            sortable={true}/>
  ]

  // Request will hide the archived row from results (Super admin only)
  const hideArchiveRow = async (row) => {
    row.hide_archive = (row.hide_archive === 1) ? 0 : 1;
    setSeed(Math.random());
    try {
      const result = await axios.post('/rapi/chemicals/organisation/archived-hide/'+row.organisation_chemical_id)
    } catch (error) {
      displayError(error)
    }
  }

  const updateArchiveBody = (row) => (
    <FlexRow key={row}>
      <Button
        primary
        icon="pi pi-eye"
        onClick={() => {
          hideArchiveRow(row);
        }}
      />
    </FlexRow>
  )
  if (organisation.enable_chemical_list_article_numbers) {
    columns.push(
      <Column
        key="organisation_article_number"
        field="organisation_article_number"
        header={intl.formatMessage({ id: 'chemicals.organisation_article_number_short' })}
        sortable={true}
      />
    )
  }
  columns.push(<Column key="name" field="name" header={intl.formatMessage({ id: 'chemicals.product' })}
                       sortable={true}/>)
  if (organisation.enable_chemical_list_synonyms) {
    columns.push(
      <Column
        key="organisation_synonym"
        field="organisation_synonym"
        header={intl.formatMessage({ id: 'chemicals.organisation_synonym_short' })}
        sortable={true}
      />
    )
  }
  columns.push(
    <Column key="location" field="locationDisplayname" header={intl.formatMessage({ id: 'common.location' })}
            sortable={false}/>,
    <Column key="supplier" field="supplier" header={intl.formatMessage({ id: 'common.supplier' })} sortable={true}/>
  )

  if (!archive) {
    columns.push(
      <Column key="status" field="status" header={intl.formatMessage({ id: 'common.status' })} body={statusBody}
              sortable={true}/>
    )
  }

  columns.push(
    <Column key="markings" style={{ width: '130px' }} body={TableMarkings}
            header={intl.formatMessage({ id: 'common.marking' })}/>
  )

  if (archive) {
    columns.push(
      <Column
        key="organisation_added_at"
        field="organisation_added_at"
        header={intl.formatMessage({ id: 'chemicals.added_at' })}
        sortable={true}
      />,
      <Column
        key="organisation_removed_at"
        field="organisation_removed_at"
        header={intl.formatMessage({ id: 'chemicals.removed_at' })}
        sortable={true}
      />
    )

    if(user.isSuperAdmin) {
      columns.push(
        <Column
          key="hide_archive"
          body={updateArchiveBody}
          header={intl.formatMessage({ id: 'common.hide' })}
          sortable={false}
        />
      )
    }
  }

  const updateLocationFilter = (updatedList) => {
    let locations = { ...filter.locations }
    let index = -1
    locations.locationNames.forEach((item, i) => {
      if (_.findIndex(updatedList, { label: item }) === -1) {
        index = i
      }
    })
    locations.locationNames.splice(index, 1)
    locations.selectedKeys.splice(index, 1)
    filter.setLocations(locations)
  }

  const dataTableRef = useRef()
  const exportCSVexternal = () => {
    const archiveParam = (archive ? '1' : '0');
    const queryStr = getUrlParams(page, rows, inputFilter) + "&archived=" + archiveParam;
    window.open('/rapi/chemicals/csv/export?' + queryStr + '&numItems=' + chemicalList.total);
  }

  const exportSds = () => {
    window.open('/rapi/chemicals/sds/export')
  }

  const ChemicalTable = () => {
    return (
        <ThinHeaderDataTable
        ref={dataTableRef}
        className={'flextable ' + !archive ? 'clickable-rows' : ''}
        loading={isLoading}
        value={chemicalList.data}
        paginator={true}
        rows={rows}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        totalRecords={chemicalList.total ? chemicalList.total : 0}
        lazy={true}
        first={first}
        sortField={sort.field}
        sortOrder={sort.order}
        onSort={(e) => {
          setSort({ field: e.sortField, order: e.sortOrder })
        }}
        onPage={(e) => {
          setFirst(e.first)
          setRows(e.rows)
          setPage(e.page + 1)
        }}
        rowClassName={(row) => {
          return {
            disabled: archive,
            'hide-archive': (row.hide_archive === 1)
          }
        }}
        onRowClick={(event) => {
          showProduct(event.data)
        }}>
        {columns}
      </ThinHeaderDataTable>
    )
  }

  return (
    <>
      {selectProductPage && (
        <ProductModalWrapper productId={selectProductPage} onClose={() => setSelectProductPage(false)} />
      )}
      <ArchiveProductDialog
        isOpen={deleteProductId !== null}
        productId={deleteProductId}
        close={() => setDeleteProductId(null)}
        deleteProduct={deleteProduct}
      />
      <ToolPanel>
        <SidebarWrapperMobile icon="pi pi-filter" buttonText={intl.formatMessage({ id: 'common.filter' })}>
          <ChemicalFilter filter={filter} setFirst={setFirst}/>
        </SidebarWrapperMobile>

        <Button icon="pi pi-file-excel" label={intl.formatMessage({ id: 'admin.export_csv' })} onClick={exportCSVexternal}/>
        {user.isSuperAdmin && (
          <Button icon="pi pi-file-pdf" label={intl.formatMessage({ id: 'chemicals.export_sds' })} onClick={exportSds}/>
        )}
      </ToolPanel>
      <FilterTableContainer>
        <TableContainer>
          <BadgeList
            sources={[
              { value: filter.he, color: filterColors.he, update: filter.setHe },
              { value: filter.pd, color: filterColors.pd, update: filter.setPd },
              { value: filter.prio, color: filterColors.prio, update: filter.setPrio },
              {
                value: filter.candidate ? [{ label: intl.formatMessage({ id: 'chemicals.form.ingredient.listed_candidate' }) }] : [],
                color: filterColors.candidate,
                update: filter.handleSetCandidate
              },
              {
                value: filter.locations.locationNames ? filter.locations.locationNames.map((l) => ({ label: l })) : [],
                color: filterColors.location,
                update: updateLocationFilter
              },
              { value: filter.tagList, color: filterColors.tagList, update: filter.setTagList },
              { value: filter.listingsList, color: filterColors.listingsList, update: filter.setListingsList },
              { value: filter.remarkList, color: filterColors.remarkList, update: filter.setRemarkList }
            ]}
          />
          <ChemicalTable/>
        </TableContainer>
        <SidebarWrapperPage>
          <ChemicalFilter filter={filter} setFirst={setFirst}/>
        </SidebarWrapperPage>
      </FilterTableContainer>
    </>
  )
}
